const theme = {
  palette: {
    primary: {
      main: "#F0750F",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            background: "#F0750F",
            color: "#fff",
            "&:hover": {
              background: "#000",
            },
            "&:disabled": {
              color: "#fff",
              background: "#F3A25C",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            background: "#F0750F",
            color: "#fff",
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            color: "#F0750F",
          },
        },
        {
          props: { variant: "danger" },
          style: {
            background: "#ff0f0f",
            color: "#fff",
            "&:hover": {
              background: "#ff3636",
            },
          },
        },
      ],
    },
  },
};

export default theme;
