const DRAWER_WIDTH = 240;
const PER_PAGE = 10;

const PO_STATUS = ["draft", "confirmed", "closed", "void", "on hold"];

const QUANTITY_TYPE = {
  FIXED: "Fixed",
  RANGE: "Range",
};

export { DRAWER_WIDTH, PER_PAGE, PO_STATUS, QUANTITY_TYPE };
