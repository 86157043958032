import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { URL_LOGIN } from "Helpers/urls";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import themeTemplate from "Styles/theme";
import ProtectedPath from "./protected";

// Components
const Login = lazy(() => import("../Components/Login"));

const AppRoutes = () => {
  const theme = createTheme(themeTemplate);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={URL_LOGIN} element={<Login />} />
          <Route path="/*" element={<ProtectedPath />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRoutes;
