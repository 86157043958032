import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDoneOutlined";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import * as handler from "../../Services/handler";
import { CSVLink } from "react-csv";
import Modal from "@mui/material/Modal";
import CSVReader from "react-csv-reader";
import Swal from "sweetalert2";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Items_vTwo() {
  const centerFlex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const InputStyle = {
    paddingBottom: "1rem",
    fontFamily: "Poppins",
    color: "black",
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  //************************************************************************************************ */
  //   default
  const [exportData, setExportData] = useState(null);
  const [exportTotal, setExportTotal] = useState(0);
  const [openExport, setOpenExport] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [loadingExport, setLoadingExport] = React.useState(false);
  //   upload def

  const [UploadData, setUploadData] = useState(null);
  const [validateLoader, setvalidateLoader] = useState(false);
  const [Compatible, setCompatible] = React.useState(false);
  const [submitloader, setsubmitloader] = React.useState(false);
  const [StatusText, setStatusText] = React.useState("");
  const [StatusTextExport, setStatusTextExport] = React.useState("");

  //   advance

  const [exportDataAdv, setExportDataAdv] = useState(null);
  const [openExportAdv, setOpenExportAdv] = React.useState(false);
  const [openUploadAdv, setOpenUploadAdv] = React.useState(false);
  const [loadingExportAdv, setLoadingExportAdv] = React.useState(false);
  const [StatusTextAdv, setStatusTextAdv] = React.useState("");
  const [StatusTextExportAdv, setStatusTextExportAdv] = React.useState("");

  // upload adv

  const [UploadDataAdv, setUploadDataAdv] = useState(null);
  const [validateLoaderAdv, setvalidateLoaderAdv] = useState(false);
  const [CompatibleAdv, setCompatibleAdv] = React.useState(false);
  const [submitloaderAdv, setsubmitloaderAdv] = React.useState(false);

  const FetchItems = async () => {
    setLoadingExport(true);
    let condition = 1;
    let disconnect = 0;
    let page = 1;

    let ExpoData = { csvItemHeader: [], csvItemData: [] };
    while (disconnect < condition) {
      const item = await handler.get(
        `/export-items-default?perPage=10&page=${page}`
      );
      if (page == 1) {
        localStorage.setItem("ExportTotal", JSON.stringify(item.total));
        ExpoData.csvItemHeader = item.csvItemHeader;
      }
      if (item.csvItemData.length > 0) {
        ExpoData.csvItemData.push(...item.csvItemData);
      } else {
        disconnect = 1;
      }
      page = page + 1;
      const total = parseInt(localStorage.getItem("ExportTotal"));
      setStatusTextExport(
        `items -> ${Math.round(
          (ExpoData.csvItemData.length / total) * 100
        )}% fetched`
      );
    }
    setLoadingExport(false);
    setExportData(ExpoData);
  };

  const FetchItemsAdv = async () => {
    setLoadingExportAdv(true);
    let condition = 1;
    let disconnect = 0;
    let page = 1;

    let ExpoData = { csvItemHeader: [], csvItemData: [] };
    while (disconnect < condition) {
      const item = await handler.get(
        `/export-items-advance?perPage=10&page=${page}`
      );
      if (page == 1) {
        localStorage.setItem("ExportTotalAdv", JSON.stringify(item.total));
        ExpoData.csvItemHeader = item.csvItemHeader;
      }
      if (item.csvItemData.length > 0) {
        ExpoData.csvItemData.push(...item.csvItemData);
      } else {
        disconnect = 1;
      }
      page = page + 1;
      const total = parseInt(localStorage.getItem("ExportTotalAdv"));
      setStatusTextExportAdv(
        `Advance configs -> ${Math.round(
          (ExpoData.csvItemData.length / total) * 100
        )}% fetched`
      );
    }
    setLoadingExportAdv(false);
    setExportDataAdv(ExpoData);
  };

  const handleUpload = (data, fileInfo) => {
    setUploadData(data);
    setStatusText("Please validate the Uploaded file");
  };

  const handleUploadAdv = (data, fileInfo) => {
    setUploadDataAdv(data);
    setStatusTextAdv("Please validate the Uploaded file");
  };

  const handleValidate = async () => {
    setvalidateLoader(true);
    try {
      for (let i = 0; i < UploadData.length; i++) {
        const item = [UploadData[i]];
        const uploadRes = await axios.post(`${BASE_URL}/check-items-default`, {
          csvItemData: item,
        });
        if (uploadRes.data.success) {
          setStatusText(
            `item -> ${item[0].item_code} row -> ${i + 1}/${
              UploadData.length
            } validated`
          );
          localStorage.setItem("deffail", "false");
        } else {
          Swal.fire({
            icon: "error",
            text: `${uploadRes.data.message} at row ${i + 1}`,
            customClass: {
              container: "swal-container",
            },
          });
          setOpenUpload(false);
          setUploadData(null);
          setCompatible(false);
          setStatusText("");
          setvalidateLoader(false);
          localStorage.setItem("deffail", "true");
          break;
        }
      }
      if (!JSON.parse(localStorage.getItem("deffail"))) {
        setCompatible(true);
        setvalidateLoader(false);
        setStatusText(
          `row -> ${UploadData.length}/${UploadData.length} validated`
        );
      }
    } catch (er) {
      setvalidateLoader(false);
      setOpenUpload(false);
      setUploadData(null);
      setCompatible(false);
      setStatusText("");

      if (er.response) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(er.response.data),
          customClass: {
            container: "swal-container",
          },
        });
      } else {
        console.log(er, "something went wrong");
        Swal.fire({
          icon: "error",
          text: "something went wrong",
          customClass: {
            container: "swal-container",
          },
        });
      }
    }
  };

  const handleValidateAdv = async () => {
    setvalidateLoaderAdv(true);
    try {
      for (let i = 0; i < UploadDataAdv.length; i++) {
        const item = [UploadDataAdv[i]];
        const uploadRes = await axios.post(`${BASE_URL}/check-items-advance`, {
          csvItemData: item,
        });
        if (uploadRes.data.success) {
          setStatusTextAdv(
            `item -> ${item[0].item_code} row -> ${i + 1}/${
              UploadDataAdv.length
            } validated`
          );
          localStorage.setItem("advfail", "false");
        } else {
          Swal.fire({
            icon: "error",
            text: `${uploadRes.data.message} at row ${i + 1}`,
            customClass: {
              container: "swal-container",
            },
          });
          setOpenUploadAdv(false);
          setUploadDataAdv(null);
          setCompatibleAdv(false);
          setStatusTextAdv("");
          localStorage.setItem("advfail", "true");
          setvalidateLoaderAdv(false);

          break;
        }
      }

      if (!JSON.parse(localStorage.getItem("advfail"))) {
        setCompatibleAdv(true);
        setvalidateLoaderAdv(false);
        setStatusTextAdv(
          `row -> ${UploadDataAdv.length}/${UploadDataAdv.length} validated`
        );
      }
    } catch (er) {
      setvalidateLoaderAdv(false);
      setOpenUploadAdv(false);
      setUploadDataAdv(null);
      setCompatibleAdv(false);
      setStatusTextAdv("");

      if (er.response) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(er.response.data),
          customClass: {
            container: "swal-container",
          },
        });
      } else {
        console.log(er, "something went wrong");
        Swal.fire({
          icon: "error",
          text: "something went wrong",
          customClass: {
            container: "swal-container",
          },
        });
      }
    }
  };

  const handleSubmit = async () => {
    setsubmitloader(true);
    try {
      for (let i = 0; i < UploadData.length; i++) {
        const item = [UploadData[i]];
        const uploadRes = await axios.post(`${BASE_URL}/upload-items-default`, {
          csvItemData: item,
        });
        if (uploadRes.data.success) {
          setStatusText(
            `item -> ${item[0].item_code} row -> ${i + 1}/${
              UploadData.length
            } uploaded`
          );
          localStorage.setItem("failSubDev", "false");
        } else {
          Swal.fire({
            icon: "error",
            text: uploadRes.data.message,
            customClass: {
              container: "swal-container",
            },
          });
          setOpenUpload(false);
          setUploadData(null);
          setCompatible(false);
          setsubmitloader(false);
          setStatusText("");
          localStorage.setItem("failSubDev", "true");
          break;
        }
      }
      if (!JSON.parse(localStorage.getItem("failSubDev"))) {
        setsubmitloader(false);
        setCompatible(false);
        setOpenUpload(false);
        setUploadData(null);
        setStatusText("");
        Swal.fire({
          icon: "success",
          text: "All Items Synced successfully",
          customClass: {
            container: "swal-container",
          },
        });
      }
    } catch (er) {
      setsubmitloader(false);
      setOpenUpload(false);
      setUploadData(null);
      setCompatible(false);
      setStatusText("");

      if (er.response) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(er.response.data),
          customClass: {
            container: "swal-container",
          },
        });
      } else {
        console.log(er, "something went wrong");
        Swal.fire({
          icon: "error",
          text: "something went wrong",
          customClass: {
            container: "swal-container",
          },
        });
      }
    }
  };

  const handleSubmitAdv = async () => {
    setsubmitloaderAdv(true);
    try {
      for (let i = 0; i < UploadDataAdv.length; i++) {
        const item = [UploadDataAdv[i]];
        const uploadRes = await axios.post(`${BASE_URL}/upload-items-advance`, {
          csvItemData: item,
        });
        if (uploadRes.data.success) {
          setStatusTextAdv(
            `item -> ${item[0].item_code} row -> ${i + 1}/${
              UploadDataAdv.length
            } uploaded`
          );
          localStorage.setItem("failSubAdv", "false");
        } else {
          Swal.fire({
            icon: "error",
            text: uploadRes.data.message,
            customClass: {
              container: "swal-container",
            },
          });
          setOpenUploadAdv(false);
          setUploadDataAdv(null);
          setCompatibleAdv(false);
          setsubmitloaderAdv(false);
          setStatusTextAdv("");
          localStorage.setItem("failSubAdv", "true");
          break;
        }
      }

      if (!JSON.parse(localStorage.getItem("failSubAdv"))) {
        setsubmitloaderAdv(false);
        setCompatibleAdv(false);
        setOpenUploadAdv(false);
        setUploadDataAdv(null);
        setStatusTextAdv("");
        Swal.fire({
          icon: "success",
          text: "All Items Synced successfully",
          customClass: {
            container: "swal-container",
          },
        });
      }
    } catch (er) {
      setsubmitloaderAdv(false);
      setOpenUploadAdv(false);
      setUploadDataAdv(null);
      setCompatibleAdv(false);
      setStatusTextAdv("");

      if (er.response) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(er.response.data),
          customClass: {
            container: "swal-container",
          },
        });
      } else {
        console.log(er, "something went wrong");
        Swal.fire({
          icon: "error",
          text: "something went wrong",
          customClass: {
            container: "swal-container",
          },
        });
      }
    }
  };

  return (
    <>
      <Grid container spacing={3} justify="center" alignItems="center" xs={12}>
        <Grid item xs={6}>
          <Card sx={{ padding: "1rem", height: "70vh", overflow: "auto" }}>
            <Typography style={{ ...InputStyle }} sx={{}} align="left">
              Items & Default Configs
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body2">
                In order to Add / Update any item click on Export Item button &
                Add new items or update details for already existing items in
                the CSV file in given format.
              </Typography>
              <br />
              {/* <Typography variant="caption">
                    Item code should be in series & unique for every item.
                </Typography> */}

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", textDecoration: "none" }}
                  color="success"
                  startIcon={<DownloadOutlinedIcon />}
                  onClick={() => {
                    setOpenExport(true);
                  }}
                >
                  Export Items CSV
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<UploadOutlinedIcon />}
                  onClick={() => {
                    setOpenUpload(true);
                  }}
                >
                  Upload Items CSV
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ padding: "1rem", height: "70vh", overflow: "auto" }}>
            <Typography style={{ ...InputStyle }} sx={{}} align="left">
              Advance Configs for All Items
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body2">
                In order to Add / Update any Advance Config click on Export
                Configs button & Add new Advance Configs or update details for
                already existing Configs in the CSV file in given format.
              </Typography>
              <br />
              <Typography variant="caption">
                User can add multiple advance config for same item code but
                "advance_config_code" should be unique in every row of advance
                config CSV file.
              </Typography>
              <br />
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", marginTop: "1rem" }}
                  color="success"
                  startIcon={<DownloadOutlinedIcon />}
                  onClick={() => {
                    setOpenExportAdv(true);
                  }}
                >
                  Export Advance Config CSV
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<UploadOutlinedIcon />}
                  onClick={() => {
                    setOpenUploadAdv(true);
                  }}
                >
                  Upload Advance Config CSV
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* modals */}
      <Modal
        open={openExport}
        onClose={() => {
          setOpenExport(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={centerFlex}>
              {!loadingExport ? (
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", textDecoration: "none" }}
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => {
                    FetchItems();
                  }}
                  disabled={exportData}
                >
                  Fetch Items Data
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", textDecoration: "none" }}
                  color="primary"
                  endIcon={<CircularProgress color="primary" size={15} />}
                >
                  Fetching Items Data
                </Button>
              )}
            </Grid>

            <Grid item xs={12} style={centerFlex}>
              {exportData ? (
                <CSVLink
                  headers={exportData?.csvItemHeader}
                  data={exportData?.csvItemData}
                  style={{ textDecoration: "none" }}
                  filename={"Items-with-default-conf.csv"}
                >
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<DownloadDoneOutlinedIcon />}
                  >
                    Export to .CSV
                  </Button>
                </CSVLink>
              ) : null}
            </Grid>
            {StatusTextExport != "" ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "300",
                    color: "success.main",
                  }}
                  fontFamily={"Poppins"}
                >
                  {StatusTextExport}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openUpload}
        onClose={() => {
          setOpenUpload(false);
          setStatusText("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={centerFlex}>
              <CSVReader
                onFileLoaded={handleUpload}
                parserOptions={papaparseOptions}
                inputStyle={{ display: "none" }}
                inputId="csvReader_Default"
              />
              <Button
                variant="outlined"
                style={{ marginBottom: "1rem", textDecoration: "none" }}
                color="info"
                startIcon={<CloudUploadOutlinedIcon />}
                onClick={() => {
                  document.getElementById("csvReader_Default").click();
                }}
                disabled={UploadData ? true : false}
              >
                Upload CSV File
              </Button>
            </Grid>
            <Grid item xs={12} style={centerFlex}>
              {UploadData &&
                (validateLoader ? (
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<CircularProgress color="success" size={15} />}
                  >
                    Validating the File
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      handleValidate();
                    }}
                    disabled={Compatible}
                  >
                    Validate the File
                  </Button>
                ))}
            </Grid>
            {Compatible ? (
              <Grid item xs={12} style={centerFlex}>
                {submitloader ? (
                  <Button
                    variant="contained"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="info"
                    startIcon={
                      <CircularProgress style={{ color: "#fff" }} size={15} />
                    }
                  >
                    Syncing..
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="info"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            ) : null}
            {StatusText != "" ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "300",
                    color: "info.main",
                  }}
                  fontFamily={"Poppins"}
                >
                  {StatusText}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Modal>

      {/* advance Conf modals */}
      <Modal
        open={openExportAdv}
        onClose={() => {
          setOpenExportAdv(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={centerFlex}>
              {!loadingExportAdv ? (
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", textDecoration: "none" }}
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => {
                    FetchItemsAdv();
                  }}
                  disabled={exportDataAdv}
                >
                  Fetch Advance Configs
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{ marginBottom: "1rem", textDecoration: "none" }}
                  color="primary"
                  endIcon={<CircularProgress color="primary" size={15} />}
                >
                  Fetching Advance Configs
                </Button>
              )}
            </Grid>

            <Grid item xs={12} style={centerFlex}>
              {exportDataAdv ? (
                <CSVLink
                  headers={exportDataAdv?.csvItemHeader}
                  data={exportDataAdv?.csvItemData}
                  style={{ textDecoration: "none" }}
                  filename={"Advance-conf.csv"}
                >
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<DownloadDoneOutlinedIcon />}
                  >
                    Export to .CSV
                  </Button>
                </CSVLink>
              ) : null}
            </Grid>
            {StatusTextExportAdv != "" ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "300",
                    color: "success.main",
                  }}
                  fontFamily={"Poppins"}
                >
                  {StatusTextExportAdv}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Modal>

      {/* validate */}
      <Modal
        open={openUploadAdv}
        onClose={() => {
          setOpenUploadAdv(false);
          setStatusTextAdv("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={centerFlex}>
              <CSVReader
                onFileLoaded={handleUploadAdv}
                parserOptions={papaparseOptions}
                inputStyle={{ display: "none" }}
                inputId="csvReader_Adv"
              />
              <Button
                variant="outlined"
                style={{ marginBottom: "1rem", textDecoration: "none" }}
                color="info"
                startIcon={<CloudUploadOutlinedIcon />}
                onClick={() => {
                  document.getElementById("csvReader_Adv").click();
                }}
                disabled={UploadDataAdv ? true : false}
              >
                Upload CSV File
              </Button>
            </Grid>
            <Grid item xs={12} style={centerFlex}>
              {UploadDataAdv &&
                (validateLoaderAdv ? (
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<CircularProgress color="success" size={15} />}
                  >
                    Validating the File
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="success"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      handleValidateAdv();
                    }}
                    disabled={CompatibleAdv}
                  >
                    Validate the File
                  </Button>
                ))}
            </Grid>
            {CompatibleAdv ? (
              <Grid item xs={12} style={centerFlex}>
                {submitloaderAdv ? (
                  <Button
                    variant="contained"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="info"
                    startIcon={<CircularProgress color="info" size={15} />}
                  >
                    Syncing..
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{ marginBottom: "1rem", textDecoration: "none" }}
                    color="info"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      handleSubmitAdv();
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            ) : null}
            {StatusTextAdv != "" ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "300",
                    color: "info.main",
                  }}
                  fontFamily={"Poppins"}
                >
                  {StatusTextAdv}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
