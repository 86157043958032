import React from "react";
import ReactDOM from "react-dom/client";
import store from "Redux/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./Routes";
import "sweetalert2/src/sweetalert2.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Styles/global.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AppRoutes />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
