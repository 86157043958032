import { AppBar, Toolbar, Typography, Button, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { removeToken } from "Redux/Reducer/auth";
import { DRAWER_WIDTH } from "Helpers/constants";

export const TopBar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(removeToken());
  };

  return (
    <AppBar
      sx={{
        width: { sm: `calc(100% - ${DRAWER_WIDTH}px)`, zIndex: 99 },
        ml: { sm: `${DRAWER_WIDTH}px` },
        color: "#fff",
      }}
      elevation={0}
    >
      <Toolbar>
        <Typography variant="h6" flexGrow={1}>
          Seller Side Portal
        </Typography>
        <Button onClick={handleLogout} variant="text">
          Logout
        </Button>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
