const URL_LOGIN = "/login";
const URL_DASHBOARD = "/";

// Vendors
const URL_VENDORS = "/vendors";
const URL_VENDOR_ITEMS = "/vendors-items/:vendorId";
const URL_PURCHASE_ORDER = "/purchase-order";
const URL_USERS = "/users";
const URL_WHATSAPPLOGS = "/whatsapplogs";
const URL_ADD_CUSTOMERS = "/add-customers";
const URL_EDIT_CUSTOMERS = "/edit-customers/:userId";
const URL_PURCHASE_MANAGE_ORDER = "/purchase-order/manage-order/:orderId";

// Category
const URL_CATEGORIES = "/category";
const URL_CATEGORIES_V2 = "/category_v2";

// Items
const URL_ADD_ITEM = "/add-item";
const URL_EDIT_ITEM = "/edit-item/:itemId";
const URL_ITEMS = "/items";
const URL_ITEM_CONFIGS = "/items/configs/:itemId";
const URL_ADD_ITEM_CONFIGS = "/items/add-configs/:itemId/:quantityType";

//SalesPerson
const URL_SALESPERSON = "/sales-person";

//Carousel
const URL_CAROUSEL = "/admin-carousel";

// Read Google Sheet file
const URL_PRODUCT_DETAILS = "/product-detail";
const URL_DEFAULT_CONFIG = "/pincodes";

//Commerce Manager
const URL_COMMERCE_MANAGER = "commerce-manager";

// Items_v2 new path for item version 2 by samm
const ITEM_V2 = "/items-v2";

export {
  URL_LOGIN,
  URL_DASHBOARD,
  URL_VENDORS,
  URL_VENDOR_ITEMS,
  URL_PURCHASE_ORDER,
  URL_USERS,
  URL_ADD_CUSTOMERS,
  URL_EDIT_CUSTOMERS,
  URL_PURCHASE_MANAGE_ORDER,
  URL_CATEGORIES,
  URL_ADD_ITEM,
  URL_ITEMS,
  URL_ITEM_CONFIGS,
  URL_ADD_ITEM_CONFIGS,
  URL_EDIT_ITEM,
  URL_SALESPERSON,
  URL_WHATSAPPLOGS,
  URL_CAROUSEL,
  URL_PRODUCT_DETAILS,
  URL_DEFAULT_CONFIG,
  URL_COMMERCE_MANAGER,
  URL_CATEGORIES_V2,
  ITEM_V2,
};
