import axios from "axios";
import { URL_LOGIN } from "Helpers/urls";
import { removeItemFromStorage, getItemFromStorage } from "Utils/storage";
import Swal from "sweetalert2";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getItemFromStorage("token")}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    /* Any status code that lie within the range of 2xx cause this function to trigger
     Do something with response data */
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      removeItemFromStorage("token");
      window.location.href = URL_LOGIN;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);

const handleError = (error) => {
  const { response } = error;
  if (response) {
    const { status, data } = response;

    if (status === 422) {
      if (data.errors.length) {
        Swal.fire({
          icon: "error",
          text: data.errors[0].msg,
          customClass: {
            container: "swal-container",
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
          customClass: {
            container: "swal-container",
          },
        });
      }
    } else if (status) {
      Swal.fire({
        icon: "error",
        text: data.message,
        customClass: {
          container: "swal-container",
        },
      });
    }
  }
  throw error;
};

export const get = (url, params, config = {}) =>
  axios
    .get(BASE_URL + url, { params, ...config })
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const post = (url, payload, config = {}) =>
  axios
    .post(BASE_URL + url, payload, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleError(error);
    });

// Naming 'delet' is not a mistake
// 'delete' is reserved keyword we can not use.
export const delet = (url) =>
  axios
    .delete(BASE_URL + url)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const put = (url, payload) =>
  axios
    .put(BASE_URL + url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));

export const patch = (url, payload) =>
  axios
    .patch(BASE_URL + url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
