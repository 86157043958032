import { Component, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  URL_DASHBOARD,
  URL_VENDORS,
  URL_LOGIN,
  URL_VENDOR_ITEMS,
  URL_PURCHASE_ORDER,
  URL_USERS,
  URL_ADD_CUSTOMERS,
  URL_EDIT_CUSTOMERS,
  URL_PURCHASE_MANAGE_ORDER,
  URL_CATEGORIES,
  URL_ADD_ITEM,
  URL_ITEMS,
  URL_ITEM_CONFIGS,
  URL_ADD_ITEM_CONFIGS,
  URL_EDIT_ITEM,
  URL_SALESPERSON,
  URL_WHATSAPPLOGS,
  URL_CAROUSEL,
  URL_PRODUCT_DETAILS,
  URL_DEFAULT_CONFIG,
  URL_COMMERCE_MANAGER,
  URL_CATEGORIES_V2,
  ITEM_V2,
} from "Helpers/urls";
import { SidePanel } from "Components/Common/SidePanel";
import { TopBar } from "Components/Common/TopBar";
import { useSelector } from "react-redux";
import { AppWrapper } from "./App.style";

import DefaultConfig from "Components/DefaultConfig";
import Items_v2 from "Components/Items_v2/Items_vTwo";

const Dashboard = lazy(() => import("../Components/Dashboard"));
const Vendors = lazy(() => import("../Components/Vendors"));
const VendorItems = lazy(() => import("../Components/Vendors/Items"));
const PurchaseOrder = lazy(() => import("../Components/PurchaseOrder"));
const Users = lazy(() => import("../Components/Users"));
const AddCustomers = lazy(() => import("../Components/Users/AddCustomers"));
const EditCustomers = lazy(() => import("../Components/Users/editUsers"));
const PurchaseManageOrder = lazy(() =>
  import("../Components/PurchaseOrder/manageOrder")
);
const Categories = lazy(() => import("../Components/Category"));
const Categories_v2 = lazy(() => import("../Components/Category2/index2"));
const AddItem = lazy(() => import("../Components/Items/addItem"));
const Items = lazy(() => import("../Components/Items"));
const ItemConfigs = lazy(() => import("../Components/ItemConfigs"));
const AddItemConfigs = lazy(() =>
  import("../Components/ItemConfigs/addConfig")
);
const EditItem = lazy(() => import("../Components/Items/editItem"));
const SalesPerson = lazy(() => import("../Components/SalesPerson"));
const WhatsAppLogs = lazy(() =>
  import("../Components/WhatsappLogs/WhatsAppLogs")
);
const Carousel = lazy(() => import("../Components/Carousel/Carousel"));

const ProductDetails = lazy(() =>
  import("../Components/ProductDetails/Product")
);

const Commerce = lazy(() =>
  import("../Components/CommerceManager/CommercePage")
);
const ProtectedRoute = [
  {
    path: URL_DASHBOARD,
    Component: Dashboard,
  },
  {
    path: URL_VENDORS,
    Component: Vendors,
  },
  {
    path: URL_VENDOR_ITEMS,
    Component: VendorItems,
  },
  {
    path: URL_PURCHASE_ORDER,
    Component: PurchaseOrder,
  },
  {
    path: URL_USERS,
    Component: Users,
  },
  {
    path: URL_ADD_CUSTOMERS,
    Component: AddCustomers,
  },
  {
    path: URL_EDIT_CUSTOMERS,
    Component: EditCustomers,
  },
  {
    path: URL_PURCHASE_MANAGE_ORDER,
    Component: PurchaseManageOrder,
  },
  {
    path: URL_CATEGORIES,
    Component: Categories,
  },
  {
    path: URL_ADD_ITEM,
    Component: AddItem,
  },
  {
    path: URL_ITEMS,
    Component: Items,
  },
  {
    path: URL_ITEM_CONFIGS,
    Component: ItemConfigs,
  },
  {
    path: URL_ADD_ITEM_CONFIGS,
    Component: AddItemConfigs,
  },
  {
    path: URL_EDIT_ITEM,
    Component: EditItem,
  },
  {
    path: URL_WHATSAPPLOGS,
    Component: WhatsAppLogs,
  },
  {
    path: URL_CAROUSEL,
    Component: Carousel,
  },
  {
    path: URL_PRODUCT_DETAILS,
    Component: ProductDetails,
  },
  {
    path: URL_DEFAULT_CONFIG,
    Component: DefaultConfig,
  },
  {
    path: URL_COMMERCE_MANAGER,
    Component: Commerce,
  },
  {
    path: URL_CATEGORIES_V2,
    Component: Categories_v2,
  },
  {
    path: ITEM_V2,
    Component: Items_v2,
  },
];

const PrivateApp = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <AppWrapper>
      <SidePanel />
      <TopBar />
      <div className="main-body">
        <Routes>
          <Route path="/">
            {ProtectedRoute.map(({ path, Component }) => (
              <Route
                path={path}
                element={token ? <Component /> : <Navigate to={URL_LOGIN} />}
                key={path}
              />
            ))}
          </Route>
        </Routes>
      </div>
    </AppWrapper>
  );
};

export default PrivateApp;
