import { createSlice } from "@reduxjs/toolkit";
import {
  getItemFromStorage,
  removeItemFromStorage,
  setItemInStorage,
} from "Utils/storage";

export const authDataSlice = createSlice({
  name: "authData",
  initialState: {
    token: getItemFromStorage("token") || "",
  },
  reducers: {
    updateToken: (state, action) => {
      setItemInStorage("token", action.payload);
      state.token = action.payload;
    },
    removeToken: (state, action) => {
      removeItemFromStorage("token");
      state.token = "";
    },
  },
});

export const { updateToken, removeToken } = authDataSlice.actions;

export default authDataSlice.reducer;
