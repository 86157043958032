import {
  Box,
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import Fab from "@mui/material/Fab";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import CSVReader from "react-csv-reader";
import axios from "axios";
import { useEffect } from "react";
import PreviewIcon from "@mui/icons-material/OpenInNewOutlined";
import { List as VirtualList } from "react-virtualized";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CSVLink, CSVDownload } from "react-csv";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

export default function Index() {
  const fabStyle = {
    position: "absolute",
    bottom: 16,
    right: 16,
  };

  const fabStyle2 = {
    position: "absolute",
    bottom: 16,
    right: 16,
  };

  const InputStyle = {
    paddingBottom: "1rem",
    fontFamily: "Poppins",
    color: "black",
    fontSize: "1.5rem",
    fontWeight: "bold",
  };
  const InputStyle2 = {
    paddingBottom: "1rem",
    fontFamily: "Poppins",
    color: "black",
    fontSize: "1rem",
    fontWeight: "300",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
  };

  const btnstyle = {
    marginTop: "2rem",
  };

  //************************************************************************************************ */

  const [UpdatedConfig, setUpdatedConfig] = useState("");

  //Default Config States =================================================
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [Uploadinput, setUploadinput] = useState("default_");
  const [configFile, setConfigFile] = useState([]);
  const [configLabelError, setConfigLabelError] = useState(false);
  const [isExist, setisExist] = useState(false);
  const [duplicatePincode, setduplicatePincode] = useState(false);
  const [updatelist, setupdatelist] = useState(0);
  const [defaultConfigs, setdefaultConfigs] = useState([]);
  const [singleDefaultConfigItem, setsingleDefaultConfigItem] = useState(null);

  //Advance Config States =================================================
  const [Advance_Uploadinput, setAdvance_Uploadinput] = useState("advance_");
  const [Advance_configLabelError, setAdvance_ConfigLabelError] =
    useState(false);
  const [Advance_isExist, setAdvance_isExist] = useState(false);
  const [Advance_updatelist, setAdvance_updatelist] = useState(0);
  const [Advance_Configs, setAdvance_Configs] = useState([]);
  const [Advance_singleDefaultConfigItem, setAdvance_singleDefaultConfigItem] =
    useState(null);

  //************************************************************************************************ */

  //Default Config Modal States =================================================================
  const [buttonModal, setbuttonModal] = useState(false);
  const [uploadModal, setuploadModal] = useState(false);
  const [singleItemModal, setsingleItemModal] = useState(false);

  //Advance Config Modal States =================================================================
  const [Advance_buttonModal, setAdvance_buttonModal] = useState(false);
  const [Advance_uploadModal, setAdvance_uploadModal] = useState(false);
  const [Advance_singleItemModal, setAdvance_singleItemModal] = useState(false);

  // Modal Handler open/close
  const handleModal = (button, upload, singleitem, advanceinput) => {
    const modalValidator =
      button == "true"
        ? setbuttonModal(true)
        : button == "false"
        ? setbuttonModal(false)
        : upload == "true"
        ? setuploadModal(true)
        : upload == "false"
        ? setuploadModal(false)
        : singleitem == "true"
        ? setsingleItemModal(true)
        : singleitem == "false"
        ? setsingleItemModal(false)
        : advanceinput == "true"
        ? setAdvance_uploadModal(true)
        : advanceinput == "false"
        ? setAdvance_uploadModal(false)
        : null;
    return modalValidator;
  };

  const onUploadLabelChange = (event) => {
    if (event.target.value.startsWith("default_")) {
      if (event.target.value.match(/^([_,a-z]*)$/)) {
        console.log("match!");
        setConfigLabelError(false);
        setisExist(false);
        setUploadinput(event.target.value);
      } else {
        setConfigLabelError(true);
      }
    } else {
      console.log(event, "false input");
      setConfigLabelError(true);
    }
  };

  const onAdvanceUploadLabelChange = (event) => {
    if (event.target.value.startsWith("advance_")) {
      if (event.target.value.match(/^([_,a-z]*)$/)) {
        console.log("match!");
        setAdvance_ConfigLabelError(false);
        setAdvance_isExist(false);
        setAdvance_Uploadinput(event.target.value);
        console.log("INPUT STATE", Advance_Uploadinput);
      } else {
        setAdvance_ConfigLabelError(true);
      }
    } else {
      console.log(event, "false input");
      setAdvance_ConfigLabelError(true);
    }
  };

  //check labelname if exist from mongo
  const labelValidator = (props) => {
    if (props == "default") {
      axios
        .post(`${BASE_URL}/check-default-configs`, {
          data: { configLabel: `${Uploadinput}` },
        })
        .then((data) => {
          const isExistLabel = data.data.sucess;
          console.log(data, "data");
          if (isExistLabel) {
            console.log("true", isExistLabel);
            setisExist(true);
          } else {
            console.log("false", isExistLabel);
            handleSubmit("csv_Reader_samm");
            setisExist(false);
          }
        });
    } else if (props == "advance") {
      axios
        .post(`${BASE_URL}/check-advance-configs`, {
          data: { configLabel: `${Advance_Uploadinput}` },
        })
        .then((data) => {
          const isExistLabel_advance = data.data.sucess;
          console.log(data, "data");
          if (isExistLabel_advance) {
            console.log("true", isExistLabel_advance);
            setAdvance_isExist(true);
          } else {
            console.log("false", isExistLabel_advance);
            handleSubmit("csv_Reader_samm_advance");
            setAdvance_isExist(false);
          }
        });
    } else {
      console.log("VALIDATOR ERR", props);
    }
  };

  //CSV Reader Functions
  const handleForce = (data, fileInfo) => {
    console.log(fileInfo);
    let type = "default";
    utilFnc(data, fileInfo, type);
  };

  const handleForceAdvance = (data, fileInfo) => {
    console.log(fileInfo);
    let type = "advance";
    utilFnc(data, fileInfo, type);
  };



  //************************************************************************************************ */
  //for update of pincodes in advance & Default
  const handleForceUpdate = (data, fileInfo) => {
    console.log(fileInfo, "TYPE: UPDATE INFO");
    let typeis = "default_";
    utilFncUpdate(data, fileInfo, typeis);
  };
  const handleForceUpdateAdvance = (data, fileInfo) => {
    console.log(fileInfo, "TYPE: UPDATE INFO");
    let typeis = "advance_";
    utilFncUpdate(data, fileInfo, typeis);
  };
  //************************************************************************************************ */

  const utilFncUpdate = (data, fileInfo, typeis) => {
    const validate = (a) => {
      return /^(\d{6}|\d{6})$/.test(a);
    };
    const filteredData = [];
    let pincodeError = false;
    data.map((row) => {
      if (filteredData.indexOf(parseInt(row[0])) !== -1) {
        pincodeError = true;
      } else {
        filteredData.push(row[0]);
      }
    });

    if (pincodeError) {
      Swal.fire({ icon: "error", text: "Duplicate Pincodes Found" });
    } else {
      let error_csv_format = false;
      filteredData.forEach((pincode) => {
        if (!validate(pincode)) {
          //error
          error_csv_format = true;
          Swal.fire({ icon: "error", text: "Invalid csv format" });
          console.log("error ", pincode);
        }
      });

      if (!error_csv_format) {
        if (typeis == "default_") {
          console.log(filteredData, "UPDATED PINCODES ARRAY");
          axios
            .post(`${BASE_URL}/update-pincodes`, {
              data: {
                id: `${UpdatedConfig}`,
                type: `${typeis}`,
                pincodes: filteredData,
              },
            })
            .then((data) => {
              const responseData = data.data.sucess;
              if (responseData) {
                Swal.fire({
                  icon: "success",
                  text: "CSV Successfully Uploaded",
                });
                setUpdatedConfig('');
                // idofmydoc = "";
                setupdatelist(updatelist + 1);
                // setUploadinput("default_");
                console.log("SUCCESS UPDATE", UpdatedConfig);
              } else {
                Swal.fire({ icon: "error", text: data.data.msg });
              }
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: "something went wrong" });
              console.log(err, "UPLOAD ERROR");
            });
        } else if (typeis == "advance_") {
          axios
            .post(`${BASE_URL}/update-pincodes`, {
              data: {
                id: `${UpdatedConfig}`,
                type: `${typeis}`,
                pincodes: filteredData,
              },
            })
            .then((data) => {
              const responseData = data.data.sucess;
              if (responseData) {
                Swal.fire({
                  icon: "success",
                  text: "CSV Successfully Uploaded",
                });
                setUpdatedConfig('');
                // idofmydoc = "";
                setupdatelist(updatelist + 1);
                // setAdvance_Uploadinput("advance_");
              } else {
                Swal.fire({ icon: "error", text: data.data.msg });
              }
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: "something went wrong" });
              console.log(err, "UPLOAD ERROR");
            });
        } else {
          console.log("UTIL FUN ERROR", data);
        }
      }
      console.log(filteredData);
    }
  };

  //function to read all the csv filess and send the data to api
  const utilFnc = (data, fileInfo, type) => {
    const validate = (a) => {
      return /^(\d{6}|\d{6})$/.test(a);
    };
    const filteredData = [];
    let pincodeError = false;
    data.map((row) => {
      if (filteredData.indexOf(parseInt(row[0])) !== -1) {
        pincodeError = true;
      } else {
        filteredData.push(row[0]);
      }
    });

    if (pincodeError) {
      Swal.fire({ icon: "error", text: "Duplicate Pincodes Found" });
    } else {
      let error_csv_format = false;
      filteredData.forEach((pincode) => {
        if (!validate(pincode)) {
          //error
          error_csv_format = true;
          handleModal(null, "true");
          Swal.fire({ icon: "error", text: "Invalid csv format" });
          console.log("error ", pincode);
        }
      });

      if (!error_csv_format) {
        if (type == "default") {
          handleModal(null, "false");
          axios
            .post(`${BASE_URL}/add-default-configs`, {
              data: {
                configLabel: `${Uploadinput}`,
                pincodes: filteredData,
              },
            })
            .then((data) => {
              const responseData = data.data.sucess;
              if (responseData) {
                Swal.fire({
                  icon: "success",
                  text: "CSV Successfully Uploaded",
                });
                setupdatelist(updatelist + 1);
                setUploadinput("default_");
              } else {
                Swal.fire({ icon: "error", text: data.data.msg });
              }
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: "something went wrong" });
              console.log(err, "UPLOAD ERROR");
            });
        } else if (type == "advance") {
          handleModal(null, null, null, "false");
          axios
            .post(`${BASE_URL}/add-advance-configs`, {
              data: {
                configLabel: `${Advance_Uploadinput}`,
                pincodes: filteredData,
              },
            })
            .then((data) => {
              const responseData = data.data.sucess;
              if (responseData) {
                Swal.fire({
                  icon: "success",
                  text: "CSV Successfully Uploaded",
                });
                setupdatelist(updatelist + 1);
                setAdvance_Uploadinput("advance_");
              } else {
                Swal.fire({ icon: "error", text: data.data.msg });
              }
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: "something went wrong" });
              console.log(err, "UPLOAD ERROR");
            });
        } else {
          console.log("UTIL FUN ERROR", data);
        }
      }
      console.log(filteredData);
    }
  };

  //************************************************************************************************ */
  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: false,
  };

  let idofmydoc = "";

  const handleSubmit = (El_id, updatedconfig) => {
    idofmydoc = `${updatedconfig}`;
    // setUpdatedConfig(`${idofmydoc}`);
    document.getElementById(El_id).click();
    console.log(El_id, "id", `${idofmydoc}`);
  };

  const DefaultConfigData = () => {
    axios.get(`${BASE_URL}/get-default-configs`).then((data) => {
      const responseBody = data.data.Data;
      console.log(responseBody, "Response body: ");
      setdefaultConfigs(responseBody);
    });
  };
  const AdvanceConfigData = () => {
    axios.get(`${BASE_URL}/get-advance-configs`).then((data) => {
      const responseBody = data.data.Data;
      console.log(responseBody, "Response body: ");
      setAdvance_Configs(responseBody);
    });
  };
  //useEffect to fetch list of all default configs
  useEffect(() => {
    DefaultConfigData();
    AdvanceConfigData();
  }, [updatelist]);

  //function for item in new modalValidator
  const singleConfigModal = (data) => {
    setsingleDefaultConfigItem(data);
    handleModal(null, null, "true");
  };

  function rowRenderer({ key, index, isScrolling, isVisible, style }) {
    return (
      <List key={key} style={{ ...style }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontFamily: "Poppins",
          }}
        >
          {singleDefaultConfigItem.pincodes[index]}
        </div>
      </List>
    );
  }

  const PincodeArrayOfString = (pincodes) => {
    let string_pincodes_arrray = [];
    if (pincodes) {
      pincodes.map((item) => {
        if (item !== null) {
          string_pincodes_arrray.push({
            pincode: item.toString(),
          });
        }
      });
    }
    return string_pincodes_arrray;
  };


  //rerendering stop by own custom fucn
  let strictRerenderBlocker = 0;
  const dontRerender = (idofitem, elidname) => {
    if (strictRerenderBlocker === 0) {
      const newid = `${idofitem}`;
      setUpdatedConfig(newid);
      console.log(idofitem, "I am Reder free item IF");
      strictRerenderBlocker = 1;
      handleSubmit(`${elidname}`);
    } else {
      strictRerenderBlocker = 0;
    }
  };
  return (
    <>
      <Grid container spacing={3} justify="center" alignItems="center" xs={12}>
        <Grid item xs={6}>
          <Card sx={{ padding: "1rem", height: "70vh", overflow: "auto" }}>
            <Typography style={{ ...InputStyle }} sx={{}} align="left">
              List Of Default Pincodes
            </Typography>
            <Grid item xs={12}>
              <List>
                {defaultConfigs.map((item) => {
                  return (
                    <ListItem
                      key={item._id}
                      secondaryAction={
                        <>
                          <IconButton
                            style={{ marginRight: "0.5rem" }}
                            onClick={() => {
                              dontRerender(item._id,'csv_Reader_samm_update');
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <FileUploadOutlinedIcon />
                            <CSVReader
                              cssClass="react-csv-input"
                              onFileLoaded={handleForceUpdate}
                              parserOptions={papaparseOptions}
                              inputStyle={{ display: "none" }}
                              inputId="csv_Reader_samm_update"
                            />
                          </IconButton>
                          <IconButton
                            style={{ marginRight: "0.5rem" }}
                            onClick={() => {
                              //   singleConfigModal(item);
                              console.log(
                                "TEST",
                                item.pincodes.join().split(",")
                              );
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              headers={[
                                {
                                  label: `${item.configLabel} Pincodes note:(Remove This Line Before Uploading Again)`,
                                  key: "pincode",
                                },
                              ]}
                              data={PincodeArrayOfString(item.pincodes)}
                              filename={`${item.configLabel}.csv`}
                            >
                              <FileDownloadOutlinedIcon />
                            </CSVLink>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              singleConfigModal(item);
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <PreviewIcon />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemText
                        primary={`${item.configLabel}`}
                        // secondary={secondary ? 'Secondary text' : null}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ padding: "1rem", height: "70vh", overflow: "auto" }}>
            <Typography style={{ ...InputStyle }} sx={{}} align="left">
              List Of Advance Pincodes
            </Typography>
            <Grid item xs={12}>
              <List>
                {Advance_Configs.map((item) => {
                  return (
                    <ListItem
                      key={item._id}
                      secondaryAction={
                        <>
                        <IconButton
                            style={{ marginRight: "0.5rem" }}
                            onClick={() => {
                              dontRerender(item._id,'csv_Reader_samm_update_advance');
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <FileUploadOutlinedIcon />
                            <CSVReader
                              cssClass="react-csv-input"
                              onFileLoaded={handleForceUpdateAdvance}
                              parserOptions={papaparseOptions}
                              inputStyle={{ display: "none" }}
                              inputId="csv_Reader_samm_update_advance"
                            />
                          </IconButton>

                          <IconButton
                            style={{ marginRight: "0.5rem" }}
                            onClick={() => {
                              //   singleConfigModal(item);
                              console.log(
                                "TEST",
                                item.pincodes.join().split(",")
                              );
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              headers={[
                                {
                                  label: `${item.configLabel} Pincodes note:(Remove This Line Before Uploading Again)`,
                                  key: "pincode",
                                },
                              ]}
                              data={PincodeArrayOfString(item.pincodes)}
                              filename={`${item.configLabel}.csv`}
                            >
                              <FileDownloadOutlinedIcon />
                            </CSVLink>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              singleConfigModal(item);
                            }}
                            edge="end"
                            aria-label="view"
                          >
                            <PreviewIcon />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemText
                        primary={`${item.configLabel}`}
                        // secondary={secondary ? 'Secondary text' : null}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Fab
        onClick={() => {
          handleModal("true");
        }}
        color="success"
        style={{ ...fabStyle }}
        variant="extended"
      >
        <UploadIcon sx={{ mr: 1 }} />
        Upload Pincodes
      </Fab>

      {/* Buttons group modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={buttonModal}
        onClose={() => {
          handleModal("false");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={buttonModal}>
          <Box sx={style}>
            <Typography style={{ ...InputStyle }} sx={{}} align="center">
              Select Config Type
            </Typography>
            <Button
              style={{ ...btnstyle }}
              color="success"
              fullWidth
              size="large"
              variant="contained"
              startIcon={<UploadIcon />}
              onClick={() => {
                handleModal("false");
                handleModal(null, "true");
              }}
            >
              Upload Default Pincode CSV
            </Button>
            <Button
              style={{ ...btnstyle }}
              color="info"
              fullWidth
              size="large"
              variant="contained"
              startIcon={<UploadIcon />}
              onClick={() => {
                handleModal("false");
                handleModal(null, null, null, "true");
              }}
            >
              Upload Advance Pincode CSV
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for Upload Default Config Label */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={uploadModal}
        onClose={() => {
          handleModal(null, "false");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={uploadModal}>
          <Box sx={style}>
            <Typography style={{ ...InputStyle }} sx={{}} align="center">
              Enter Label For Default Pincode CSV
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter CSV Label"
              variant="outlined"
              color="success"
              value={Uploadinput}
              onChange={onUploadLabelChange}
              error={configLabelError || isExist}
              helperText={
                configLabelError
                  ? "Invalid character"
                  : isExist
                  ? "Label Name Already Exist"
                  : `Label Should Start With "advance_" and Valid characters are _ and a-z`
              }
            />
            <Button
              style={{ ...btnstyle }}
              color="success"
              fullWidth
              size="large"
              variant="contained"
              //   type="submit"
              onClick={() => {
                labelValidator("default");
              }}
            >
              Sumbit
            </Button>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={handleForce}
              parserOptions={papaparseOptions}
              inputStyle={{ display: "none" }}
              inputId="csv_Reader_samm"
            />
          </Box>
        </Fade>
      </Modal>

      {/* Modal for Upload Advance Config Label */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Advance_uploadModal}
        onClose={() => {
          handleModal(null, null, null, "false");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Advance_uploadModal}>
          <Box sx={style}>
            <Typography style={{ ...InputStyle }} sx={{}} align="center">
              Enter Label For Advnace Pincode CSV
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Enter CSV Label"
              variant="outlined"
              color="success"
              value={Advance_Uploadinput}
              onChange={onAdvanceUploadLabelChange}
              error={Advance_configLabelError || Advance_isExist}
              helperText={
                Advance_configLabelError
                  ? "Invalid character"
                  : Advance_isExist
                  ? "Label Name Already Exist"
                  : "Valid characters are _ and a-z"
              }
            />
            <Button
              style={{ ...btnstyle }}
              color="success"
              fullWidth
              size="large"
              variant="contained"
              //   type="submit"
              onClick={() => {
                labelValidator("advance");
              }}
            >
              Sumbit
            </Button>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={handleForceAdvance}
              parserOptions={papaparseOptions}
              inputStyle={{ display: "none" }}
              inputId="csv_Reader_samm_advance"
            />
          </Box>
        </Fade>
      </Modal>

      {/* Modal for single default item value */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={singleItemModal}
        onClose={() => {
          handleModal(null, null, "false");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={singleItemModal}>
          <Box sx={style}>
            <Typography style={{ ...InputStyle }} sx={{}} align="center">
              {singleDefaultConfigItem?.configLabel}
            </Typography>
            <Typography style={{ ...InputStyle2 }} sx={{}} align="center">
              Virtualized List
            </Typography>

            {singleDefaultConfigItem ? (
              <VirtualList
                width={400}
                height={300}
                rowCount={singleDefaultConfigItem.pincodes.length}
                rowHeight={40}
                rowRenderer={rowRenderer}
              />
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
