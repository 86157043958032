import React from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import {
  URL_DASHBOARD,
  URL_VENDORS,
  URL_PURCHASE_ORDER,
  URL_USERS,
  URL_CATEGORIES,
  URL_ADD_ITEM,
  URL_ITEMS,
  URL_SALESPERSON,
  URL_WHATSAPPLOGS,
  URL_CAROUSEL,
  URL_PRODUCT_DETAILS,
  URL_DEFAULT_CONFIG,
  URL_COMMERCE_MANAGER,
  URL_CATEGORIES_V2,
  ITEM_V2,
} from "Helpers/urls";
import { DRAWER_WIDTH } from "Helpers/constants";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
//icons
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"; // Carousel Image
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined"; // Product Details
import FiberPinOutlinedIcon from "@mui/icons-material/FiberPinOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
export const SidePanel = () => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      text: "Users",
      path: URL_USERS,
    },
    {
      text: "WhatsApp Logs",
      path: URL_WHATSAPPLOGS,
    },
    {
      text: "Purchase",
      child: [
        {
          text: "Purchase Orders",
          path: URL_PURCHASE_ORDER,
        },
        {
          text: "Vendors",
          path: URL_VENDORS,
        },
      ],
    },
    {
      text: "Items",
      child: [
        {
          text: "Category",
          path: URL_CATEGORIES,
        },
        {
          text: "List of Items",
          path: URL_ITEMS,
        },
        {
          text: "Category_v2",
          path: URL_CATEGORIES_V2,
        },
      ],
    },
    {
      text: "Rewards",
      path: URL_DASHBOARD,
    },
    {
      text: "Announcements and Notifications",
      path: URL_DASHBOARD,
    },
    {
      text: "Carousel",
      path: URL_CAROUSEL,
    },
    {
      text: "Product Details",
      path: URL_PRODUCT_DETAILS,
    },
    {
      text: "Manage Pincodes",
      path: URL_DEFAULT_CONFIG,
    },
    {
      text: "Commerce manager",
      path: URL_COMMERCE_MANAGER,
    },
    {
      text: "Items V2",
      path: ITEM_V2,
    },
  ];

  const iconStyles = {
    // fontSize:'0rem'
    color: "#000",
  };
  const menuText = {
    fontFamily: "Poppins",
    color: "black",
    fontWeight: "500",
  };

  //by sam
  const iconCheck = (item, child) => {
    if (child) {
      console.log("in Child", child);
      return child === "Purchase Orders" ? (
        <ShoppingCartOutlinedIcon style={{ ...iconStyles }} />
      ) : child === "Vendors" ? (
        <StorefrontOutlinedIcon style={{ ...iconStyles }} />
      ) : child === "Category" ? (
        <CategoryOutlinedIcon style={{ ...iconStyles }} />
      ) : child === "List of Items" ? (
        <ListAltOutlinedIcon style={{ ...iconStyles }} />
      ) : null;
    } else {
      return item.text == "Users" ? (
        <GroupAddOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "WhatsApp Logs" ? (
        <FindInPageOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Purchase" ? (
        <CurrencyRupeeOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Items" ? (
        <AccountTreeOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Rewards" ? (
        <EmojiEventsOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Announcements and Notifications" ? (
        <CampaignOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Carousel" ? (
        <AddToPhotosIcon style={{ ...iconStyles }} />
      ) : item.text == "Product Details" ? (
        <TableChartOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Manage Pincodes" ? (
        <PinDropOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Commerce manager" ? (
        <DriveFileMoveOutlinedIcon style={{ ...iconStyles }} />
      ) : item.text == "Items V2" ? (
        <ListAltOutlinedIcon style={{ ...iconStyles }} />
      ) : null;
    }
  };
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: { width: `${DRAWER_WIDTH}px`, zIndex: 0 },
      }}
    >
      <Typography sx={{ ...menuText }} variant="h5" p={2}>
        Inframandi
      </Typography>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <div key={item.text}>
            <ListItem disablePadding>
              {item.child ? (
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>{iconCheck(item)}</ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography style={{ ...menuText }}>
                        {item.text}
                      </Typography>
                    }
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              ) : (
                <ListItemButton href={item.path}>
                  <ListItemIcon>{iconCheck(item)}</ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography style={{ ...menuText }}>
                        {item.text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              )}
            </ListItem>
            {item.child &&
              item.child.map((childItem) => (
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  key={childItem.text}
                >
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} href={childItem.path}>
                      <ListItemIcon>
                        {iconCheck(null, childItem.text)}
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            style={{ ...menuText, fontWeight: "400" }}
                          >
                            {childItem.text}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              ))}
          </div>
        ))}
      </List>
    </Drawer>
  );
};
