import styles from "styled-components";
import { DRAWER_WIDTH } from "Helpers/constants";

const AppWrapper = styles.div`    
    height: calc(100vh - 80px);

    .main-body {    
        margin-left: ${DRAWER_WIDTH}px;  
        margin-top: 80px;
        padding: 0 20px;
    }
`;

export { AppWrapper };
